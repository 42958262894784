import React from 'react';
import Image from '../base/Image';
import FadeIn from '../molecules/FadeInWhenVisible';
interface CaptureMoreAttentionSectionProps {
  lead: string;
  title: string;
  body: string;
  tiles: {
    title: string;
    body: string;
    image: {
      sourceUrl: string;
      altText: string;
    };
  }[];
}

const TilePartial: React.FC<any> = ({ tile, index, length }) => (
  <>
    <div className="flex-1" key={index}>
      <FadeIn transition={{ delay: index / 2.5 }}>
        <Image data={tile.image} className="w-full h-auto mb-10 lg:mb-12" />
      </FadeIn>
      <FadeIn transition={{ delay: index / 2.25 }}>
        <h3 className="px-8 mb-5 text-white u-h4">{tile.title}</h3>
      </FadeIn>
      {/* <p className="px-8 text-lg leading-8 text-tertiary">
      {tile.body}
    </p> */}
    </div>
    {index < length - 1 && (
      <>
        <div
          className="hidden w-1 bg-repeat-y md:block mb-36"
          style={{
            backgroundImage: 'url(/images/line-vertical.svg)',
            backgroundSize: '1px auto',
          }}
        />
        <div
          className="h-1 bg-repeat-x"
          style={{
            backgroundImage: 'url(/images/line-horizontal.svg)',
            backgroundSize: 'auto 1px',
          }}
        />
      </>
    )}
  </>
);

const CaptureMoreAttentionSection: React.FC<CaptureMoreAttentionSectionProps> = ({
  lead,
  title,
  body,
  tiles,
}) => (
  <section
    className="py-16 bg-cover md:py-32"
    style={{
      backgroundImage:
        'linear-gradient(140.65deg, rgba(180, 28, 205, 0.83) -28.97%, rgba(95, 109, 241, 0.77) 86.26%)',
    }}
  >
    <div className="text-center u-container">
      <div className="max-w-3xl mx-auto">
        <FadeIn>
          <div className="mb-5 u-h6 text-tertiary opacity-30">{lead}</div>
        </FadeIn>
        <FadeIn transition={{ delay: 0.1 }}>
          <h2 className="mb-5 text-white u-h2">{title}</h2>
        </FadeIn>
        <FadeIn transition={{ delay: 0.2 }}>
          <p className="text-lg leading-8 text-tertiary">{body}</p>
        </FadeIn>
      </div>

      <div className="flex flex-col justify-between max-w-4xl mx-auto mt-16 space-y-16 xl:max-w-5xl md:flex-row md:space-y-0 md:space-x-14 lg:space-x-28">
        {tiles.map((tile, index) => (
          <TilePartial
            tile={tile}
            index={index}
            length={tiles.length}
            key={index}
          />
        ))}
      </div>
    </div>
  </section>
);

export default CaptureMoreAttentionSection;
