import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import BenefitSection from '../components/organisms/BenefitSection';
import CaptureMoreAttentionSection from '../components/organisms/CaptureMoreAttentionSection';
import CaseStudySection from '../components/organisms/CaseStudySection';
import ProductPageHero from '../components/organisms/ProductPageHero';
import ProductPageTestimonial from '../components/organisms/ProductPageTestimonial';

const PersonalizeToYourAudiencePage = ({ data: { page: data } }) => {
  return (
    <Layout>
      <Seo post={data} />

      <ProductPageHero
        backgroundImage=""
        {...data.personaliseToYourAudience.personaliseToYourAudienceHero}
      />
      {data.personaliseToYourAudience.personaliseToYourAudienceBenefits.length >
        0 && (
        <BenefitSection
          imageSide="right"
          {...data.personaliseToYourAudience
            .personaliseToYourAudienceBenefits[0]}
        />
      )}
      <CaptureMoreAttentionSection
        {...data.personaliseToYourAudience.captureMoreAttentionSection}
      />
      {data.personaliseToYourAudience.personaliseToYourAudienceBenefits.length >
        1 && (
        <BenefitSection
          imageSide="left"
          {...data.personaliseToYourAudience
            .personaliseToYourAudienceBenefits[1]}
        />
      )}
      {data.personaliseToYourAudience.personaliseToYourAudienceBenefits.length >
        2 && (
        <BenefitSection
          imageSide="right"
          {...data.personaliseToYourAudience
            .personaliseToYourAudienceBenefits[2]}
        />
      )}
      <ProductPageTestimonial
        {...data.personaliseToYourAudience.personaliseToYourAudienceTestimonial}
        backgroundImage="/images/personalise-to-your-audience-testimonial-bg.jpg"
      />
      {data.personaliseToYourAudience.personaliseToYourAudienceBenefits.length >
        3 && (
        <BenefitSection
          imageSide="left"
          {...data.personaliseToYourAudience
            .personaliseToYourAudienceBenefits[3]}
        />
      )}

      <CaseStudySection
        {...data.personaliseToYourAudience.caseStudy}
        backgroundImage="linear-gradient(107.76deg, #C96DC5 -3.7%, #5F6DF1 85.13%)"
      />
    </Layout>
  );
};

export const pageQuery = graphql`
  query PersonaliseToYourAudienceQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      slug
      ...SEO
      personaliseToYourAudience {
        personaliseToYourAudienceHero {
          title
          body
          image {
            ...Image
          }
        }
        personaliseToYourAudienceBenefits {
          lead
          title
          body
          image {
            ...Image
          }
          cta {
            to
            label
          }
        }
        captureMoreAttentionSection {
          lead
          title
          body
          tiles {
            title
            body
            image {
              ...Image
            }
          }
        }
        personaliseToYourAudienceTestimonial {
          logo {
            ...Image
          }
          quote
          author {
            name
            title
            photo {
              ...Image
            }
          }
        }
        caseStudy {
          logo {
            ...Image
          }
          title
          body
          cta {
            label
            to
          }
          quote
          author {
            name
            title
            photo {
              ...Image
            }
          }
        }
      }
    }
  }
`;

export default PersonalizeToYourAudiencePage;
